<ng-container *ngIf="userProfile">
  <h2>{{ 'userInfo.welcome' | translate }} {{ userProfile.firstName }}</h2>
  <mat-card>
    <table class="userinfos">
      <tr *ngIf="userProfile.username">
        <th scope="row">{{ 'userInfo.username' | translate }}</th>
        <td>{{ userProfile.username }}</td>
      </tr>
      <tr *ngIf="userProfile.firstName">
        <th scope="row">{{ 'userInfo.firstName' | translate }}</th>
        <td>{{ userProfile.firstName }}</td>
      </tr>
      <tr *ngIf="userProfile.lastName">
        <th scope="row">{{ 'userInfo.lastName' | translate }}</th>
        <td>{{ userProfile.lastName }}</td>
      </tr>
      <tr *ngIf="userProfile.email">
        <th scope="row">{{ 'userInfo.email' | translate }}</th>
        <td>{{ userProfile.email }}</td>
      </tr>
      <tr *ngIf="idp">
        <th scope="row">{{ 'userInfo.idp' | translate }}</th>
        <td>{{ idp | translate }}</td>
      </tr>
    </table>
  </mat-card>
</ng-container>
