<ng-container *ngIf="userProfile">
  <app-empty-header class="header"></app-empty-header>
  <div class="content-container">
    <app-user-info [userProfile]="userProfile"></app-user-info>
    <app-security [userProfile]="userProfile"></app-security>
    <app-client-downloads></app-client-downloads>
  </div>
</ng-container>
<ng-container *ngIf="!userProfile">
  <div class="center">
    <div class="loader"></div>
  </div>
</ng-container>
