import {Routes} from '@angular/router';
import {AuthGuard} from './auth.guard';
import {AppComponent} from './app.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AppComponent,
  },
];
