<mat-toolbar class="mat-toolbar header mat-toolbar-single-row">
  <div class="complogo">
    <img class="logo" src="assets/company-logo.svg" alt="Utimaco"/>
  </div>
  <div class="menu">
    <button mat-icon-button matTooltip="{{ 'header.logout' | translate }}" (click)="logout()">
      <mat-icon class="logout">logout</mat-icon>
    </button>
  </div>
</mat-toolbar>
