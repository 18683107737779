import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakProfile} from 'keycloak-js';
import {ClientDownloadsComponent} from './client-downloads/client-downloads.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {EmptyHeaderComponent} from './containers/empty-header/empty-header.component';
import {UserInfoComponent} from './user-info/user-info.component';
import {MatCard, MatCardContent, MatCardFooter} from '@angular/material/card';
import {SecurityComponent} from './security/security.component';
import {LogoutService} from './services/logout.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    ClientDownloadsComponent,
    MatButtonModule,
    EmptyHeaderComponent,
    UserInfoComponent,
    EmptyHeaderComponent,
    TranslateModule,
    MatCard,
    MatCardContent,
    MatCardFooter,
    SecurityComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  loggedIn = false;
  userProfile?: KeycloakProfile;

  constructor(
    private readonly keycloak: KeycloakService,
    private translateService: TranslateService,
    private logoutService: LogoutService
  ) {
    const language = navigator.language.split('-')[0];
    if (language === 'de' || language === 'en') {
      this.translateService.setDefaultLang(language);
    } else {
      this.translateService.setDefaultLang('en');
    }
  }

  public async ngOnInit() {
    this.loggedIn = this.keycloak.isLoggedIn();
    this.logoutService.enableAutoLogout().subscribe();
    if (this.loggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
    }
  }
}
