import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Configuration, FileService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc/';

import {environment} from '../environments/environment';
import {KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class ApiClientFactoryService {
  constructor(
    private httpClient: HttpClient,
    protected readonly keycloak: KeycloakService
  ) {}

  public getFileService(): FileService {
    return new FileService(this.httpClient, environment.backends.portalFeCmpBaseUrl, new Configuration());
  }
}
