import {NgOptimizedImage} from '@angular/common';
import {Component} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {ApiClientFactoryService} from '../apiclient-factory.service';
import {DownloadFileResponseDto, FileService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-client-downloads',
  standalone: true,
  imports: [MatCardModule, TranslateModule, NgOptimizedImage],
  templateUrl: './client-downloads.component.html',
  styleUrl: './client-downloads.component.scss',
})
export class ClientDownloadsComponent {
  private fileService: FileService;

  LC2GO_WIN = 'LC2GO_WINDOWS';
  LC2GO_MAC = 'LC2GO_MACOS';
  LC2GO_LINUX = 'LC2GO_LINUX';

  LC_WIN = 'LANCRYPT_WINDOWS';
  LC_MAC = 'LANCRYPT_MACOS';

  constructor(private apiClientFactory: ApiClientFactoryService) {
    this.fileService = apiClientFactory.getFileService();
  }

  public downloadFile(filetype: string) {
    this.fileService.downloadFile(filetype).subscribe({
      next: (n: DownloadFileResponseDto) => {
        window.open(n.downloadLink);
      },
      error: (e: HttpErrorResponse) => {
        console.log(e);
      },
    });
  }
}
