import {Component, Input} from '@angular/core';
import {KeycloakLoginOptions, KeycloakProfile} from 'keycloak-js';
import {MatCard, MatCardContent, MatCardFooter} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {KeycloakService} from 'keycloak-angular';
import {NgIf} from '@angular/common';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-security',
  standalone: true,
  imports: [MatCard, MatCardContent, MatCardFooter, TranslateModule, NgIf, MatButton],
  templateUrl: './security.component.html',
  styleUrl: './security.component.scss',
})
export class SecurityComponent {
  @Input({required: true})
  userProfile: KeycloakProfile | undefined;
  idpRaw: string | undefined = '';

  constructor(private readonly keycloak: KeycloakService) {}

  public ngOnChanges(): void {
    if (this.userProfile) {
      const attributes = this.userProfile.attributes;
      const idp = attributes?.['idp'] as Array<string> | undefined;
      this.idpRaw = idp?.[0];
    }
  }

  public changePassword() {
    const opts: KeycloakLoginOptions = {
      action: 'UPDATE_PASSWORD',
    };

    this.keycloak.login(opts);
  }
}
