<h2>{{ 'clientsComponent.clientDownloads' | translate }}</h2>
<mat-card>
  <mat-card-title>{{ 'clientsComponent.lancrypt' | translate }}</mat-card-title>
  <mat-card-content class="nowrap">
    <a (click)="downloadFile(LC_WIN)" href="javascript: void(0)">
      <span class="icon-conpal-cib-windows icon-font"></span>
      {{ 'clientsComponent.downloadText.windows' | translate }}
    </a>
    &nbsp;&nbsp;<wbr>
    <a (click)="downloadFile(LC_MAC)" href="javascript: void(0)">
      <span class="icon-conpal-cib-apple icon-font"></span>
      {{ 'clientsComponent.downloadText.macos' | translate }}
    </a>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>{{ 'clientsComponent.lancrypt2go' | translate }}</mat-card-title>
  <mat-card-content class="nowrap">
    <a (click)="downloadFile(LC2GO_WIN)" href="javascript: void(0)">
      <span class="icon-conpal-cib-windows icon-font"></span>
      {{ 'clientsComponent.downloadText.windows' | translate }}
    </a>
    &nbsp;&nbsp;<wbr>
    <a (click)="downloadFile(LC2GO_MAC)" href="javascript: void(0)">
      <span class="icon-conpal-cib-apple icon-font"></span>
      {{ 'clientsComponent.downloadText.macosDmg' | translate }}
    </a>
    &nbsp;&nbsp;<wbr>
    <a (click)="downloadFile(LC2GO_LINUX)" href="javascript: void(0)">
      <span class="icon-conpal-cib-linux icon-font"></span>
      {{ 'clientsComponent.downloadText.linux' | translate }}
    </a>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-title>{{ 'clientsComponent.lancryptMobile' | translate }}</mat-card-title>
  <mat-card-content>
    <a href="{{'clientsComponent.downloadLinks.lcmobileIos' | translate}}">
      <img height="50" class="appstore-img" ngSrc="assets/img/ios_badge.svg"
           alt="{{'clientsComponent.altTexts.ios' | translate}}" width="150"/>
    </a>
    <a href="{{'clientsComponent.downloadLinks.lcmobileAndroid' | translate}}">
      <img height="50" class="appstore-img" alt="{{'clientsComponent.altTexts.android' | translate}}"
           ngSrc='assets/img/google-play-badge.svg' width="169"/>
    </a>
  </mat-card-content>
</mat-card>
<div class="trademark-notice">
  {{ 'clientsComponent.legal.ios' | translate }}<br/>
  {{ 'clientsComponent.legal.android' | translate }}
</div>
