import {Component} from '@angular/core';

import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {MatToolbar} from '@angular/material/toolbar';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-empty-header',
  templateUrl: './empty-header.component.html',
  standalone: true,
  imports: [MatToolbar, MatIcon, MatIconButton, MatTooltip, TranslateModule],
  styleUrls: ['./empty-header.component.scss'],
})
export class EmptyHeaderComponent {
  constructor(
    private keycloak: KeycloakService,
    private router: Router
  ) {}

  logout() {
    this.keycloak.logout(environment.keycloak.redirectUrl).then(_ => {
      this.router.navigate(['/']);
    });
  }
}
