<ng-container *ngIf="idpRaw == 'LOCAL' || idpRaw === undefined">
  <h2>{{ "security" | translate }}</h2>
  <mat-card>
    <mat-card-content>
      {{ "changePasswordDesc" | translate }}
    </mat-card-content>
    <mat-card-footer>
      <button mat-button mat-raised-button (click)="changePassword()">{{ "changePassword" | translate }}</button>
    </mat-card-footer>
  </mat-card>
</ng-container>
