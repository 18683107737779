import {NgIf} from '@angular/common';
import {Component, Input, OnChanges} from '@angular/core';
import {KeycloakProfile} from 'keycloak-js';
import {TranslateModule} from '@ngx-translate/core';
import {MatCard} from '@angular/material/card';

@Component({
  selector: 'app-user-info',
  standalone: true,
  imports: [NgIf, TranslateModule, MatCard],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss',
})
export class UserInfoComponent implements OnChanges {
  @Input({required: true})
  userProfile: KeycloakProfile | undefined;
  idp: string | undefined = '';
  idpRaw: string | undefined = '';

  public ngOnChanges(): void {
    if (this.userProfile) {
      const attributes = this.userProfile.attributes;
      const idp = attributes?.['idp'] as Array<string> | undefined;
      this.idpRaw = idp?.[0];
      this.idp = this.mapIdpTranslation(idp?.[0]);
    }
  }

  private mapIdpTranslation(idpRaw: string | undefined): string | undefined {
    switch (idpRaw) {
      case 'LOCAL':
        return 'userInfo.idps.local';
      case 'MICROSOFT':
        return 'userInfo.idps.ms';
    }
    return undefined;
  }
}
